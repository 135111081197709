import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from 'components/uiLibrary/Typography';
import TrendingArtistProfessions from 'components/Artist/Display/TrendingArtistProfessions';
import ArtistFilters from 'components/Artist/Display/IndexPageFilters';
import CastingToolPromo from 'components/Globals/CastingToolPromo';

import { useQuery } from 'utils/react-query';

import { TP } from 'constants/index';

import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import globalQueries from 'containers/Globals/queries';

import { useTranslation } from 'src/i18n';

import classes from './FeaturedArtistsSection.module.scss';

const FeaturedArtistsSection = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const isTablet = useMediaQuery('(max-width:1024px)');

  const { data: count } = useQuery(
    globalQueries.getHomeNumbers({
      queryConfig: {
        enabled: false,
        select: response => response?.data?.artists?.total,
      },
    }),
  );

  return (
    <div className={classes.fas}>
      <div className={classes.fas__header}>
        <div className={classes.title}>
          <Typography variant="h2" size={isTablet ? 20 : 32} weight="bold">
            {t(`${TP}.m_ARTISTS`)}
          </Typography>
          {!isTablet && count > 0 && (
            <Typography className={classes.count} variant="span" size={16} color="secondary">
              {count}
            </Typography>
          )}
        </div>
        {!isTablet && (
          <div className={classes.actions}>
            <CastingToolPromo
              asCTA
              trackingData={{ section: SECTIONS.HEADER, component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA }}
            />
          </div>
        )}
      </div>
      <div className={classes.fas__section}>
        <ArtistFilters concise />
      </div>
      <TrendingArtistProfessions rowLimit={10} artistsPerRow={4} />
    </div>
  );
};

export default FeaturedArtistsSection;
