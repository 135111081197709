import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import omit from 'lodash/omit';

import EntityProfileSelector from 'components/Globals/EntityProfileSelector';
import ArtistProfessionDropdown, {
  PROFESSION_TYPES,
} from 'components/Globals/FormFields/Display/ArtistProfessionDropdown';

import { ENTITY_TYPE } from 'constants/index';
import { FILTER_TYPES, FILTER_SLUG_TYPE } from 'constants/filters';
import { SECTIONS, COMPONENTS, CATEGORIES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';

import classes from './IndexPageFilters.module.scss';

const searchTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  category: CATEGORIES.SEARCH,
};

const artistProfessionTrackingData = {
  section: SECTIONS.ARTIST_LIST,
  component: COMPONENTS.FILTERS,
  category: CATEGORIES.SEARCH,
};

const IndexPageFilters = ({ concise = false }) => {
  const { navigate, filters, applyFilters } = usePageContext();

  const onSelectArtist = useCallback(
    ({ entity }) => {
      if (entity) {
        navigate.to(navigate.getLinkProps({ entityType: ENTITY_TYPE.ARTIST, entity }));
      } else {
        const queryFilterKey = FILTER_SLUG_TYPE[FILTER_TYPES.QUERY];
        const pageFilterKey = FILTER_SLUG_TYPE[FILTER_TYPES.PAGE];
        const pageFilterCurrentValue = filters?.[pageFilterKey];
        const finalFiltersExcludingPageAndQuery = omit(filters, [pageFilterKey, queryFilterKey]);

        applyFilters({
          entityType: ENTITY_TYPE.ARTIST,
          filters: {
            ...finalFiltersExcludingPageAndQuery,
            ...(pageFilterCurrentValue && Object.keys(finalFiltersExcludingPageAndQuery).length > 0
              ? { [pageFilterKey]: 1 }
              : {}),
          },
        });
      }
    },
    [applyFilters, filters, navigate],
  );

  const onSearchArtist = useCallback(
    query => {
      applyFilters({
        entityType: ENTITY_TYPE.ARTIST,
        filters: {
          ...filters,
          [FILTER_SLUG_TYPE[FILTER_TYPES.PAGE]]: 1,
          [FILTER_SLUG_TYPE[FILTER_TYPES.QUERY]]: query,
        },
      });
    },
    [filters, applyFilters],
  );

  const onSelectProfession = useCallback(
    (e, profession) => {
      const professionFilterKey = FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION];
      const pageFilterKey = FILTER_SLUG_TYPE[FILTER_TYPES.PAGE];
      const pageFilterCurrentValue = filters?.[pageFilterKey];
      const finalFiltersExcludingPage = omit(filters, [professionFilterKey, pageFilterKey]);

      if (profession) {
        finalFiltersExcludingPage[FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION]] = [profession];
      }

      applyFilters({
        entityType: ENTITY_TYPE.ARTIST,
        filters: {
          ...finalFiltersExcludingPage,
          ...(pageFilterCurrentValue && Object.keys(finalFiltersExcludingPage).length > 0
            ? { [pageFilterKey]: 1 }
            : {}),
        },
      });
    },
    [applyFilters, filters],
  );

  const selectedProfession = useMemo(() => filters?.[FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION]]?.[0], [filters]);

  const professionFields = useMemo(() => {
    if (concise) {
      return [{ fullWidth: true }];
    }

    return [
      {
        types: [PROFESSION_TYPES.VOICES],
        trackingData: { ...artistProfessionTrackingData, subComponent: SUB_COMPONENTS.VOICE_TYPES },
      },
      {
        types: [PROFESSION_TYPES.INSTRUMENTALISTS],
        trackingData: { ...artistProfessionTrackingData, subComponent: SUB_COMPONENTS.INSTRUMENTALISTS },
      },
      {
        types: [PROFESSION_TYPES.PROFESSIONS],
        trackingData: { ...artistProfessionTrackingData, subComponent: SUB_COMPONENTS.PROFESSIONS },
      },
    ];
  }, [concise]);

  return (
    <div className={classnames(classes.root, { [classes.concise]: concise })}>
      <EntityProfileSelector
        allowedEntityTypes={[ENTITY_TYPE.ARTIST]}
        onChange={onSelectArtist}
        onSearch={onSearchArtist}
        searchQuery={filters?.[FILTER_SLUG_TYPE[FILTER_TYPES.QUERY]]}
        trackingData={searchTrackingData}
        showRecentlyVisited
        fullWidth
      />
      {professionFields?.map((fieldProps, index) => (
        <ArtistProfessionDropdown
          key={index}
          value={selectedProfession}
          onChange={onSelectProfession}
          {...fieldProps}
        />
      ))}
    </div>
  );
};

export default IndexPageFilters;
