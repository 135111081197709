import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import EntityTrendingList from 'components/Globals/EntityTrendingList';
import AdvertisementRenderer from 'components/Globals/AdvertisementBlock/AdvertisementRenderer';
import ConversionFlowBanners from 'components/Globals/Banners/ConversionFlowBanners';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';
import { TertiaryButton } from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';

import { ENTITY_TYPE, TP } from 'constants/index';
import { FILTER_TYPES, FILTER_SLUG_TYPE } from 'constants/filters';

import { useQuery, useInfiniteQuery } from 'utils/react-query';
import usePageContext from 'utils/hooks/usePageContext';
import { getAdvertisementConfigs, AD_IDENTIFIER_TYPE, AD_PLACEMENTS, AD_TAGS } from 'utils/advertisements';

import queries from 'containers/Artist/queries';
import { selectTrendingArtistProfessions } from 'containers/Artist/transformers';

import { useTranslation } from 'src/i18n';

const trackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.ARTIST_CARD,
};

const seeAllTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.SEE_ALL_CTA,
};

const TrendingArtistProfessionSwimlane = ({ index, profession, limit = 8, getFooterAd }) => {
  const { navigate, queryParams } = usePageContext();
  const query = queryParams?.page || queryParams?.letter;
  const { data, isLoading } = useInfiniteQuery(
    queries.getTrendingArtist({
      filters: { primary_profession_id: profession?.id },
      limit,
    }),
  );

  const artists = useMemo(() => data?.pages?.[0]?.data?.slice(0, limit), [data, limit]);

  if (!artists?.length || artists?.length < limit) {
    return null;
  }

  return (
    <EntityTrendingList
      key={profession?.slug}
      title={profession?.name}
      count={profession?.total}
      linkProps={navigate.getLinkProps({
        entityType: ENTITY_TYPE.ARTIST,
        filters: { [FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION]]: [profession] },
        onlyLinkProps: true,
      })}
      entities={artists}
      entityType={ENTITY_TYPE.ARTIST}
      entityTrackingData={{
        ...trackingData,
        searchQuery: query,
        meta: { section: profession.slug },
      }}
      seeAllTrackingData={{
        ...seeAllTrackingData,
        meta: { section: profession.slug },
      }}
      isLoading={isLoading}
      footerContent={getFooterAd({ index, count: data?.pages?.[0]?.total })}
      condensed={limit < 8}
      inline
    />
  );
};

const TrendingArtistProfessions = ({ rowLimit, artistsPerRow = 8 }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate } = usePageContext();
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [visibleRows, setVisibleRows] = useState(rowLimit || 5);

  useEffect(() => {
    setVisibleRows(prevRows => (inView ? prevRows + 5 : prevRows));
  }, [inView]);

  const { data } = useQuery(
    queries.getArtistProfessions({
      queryConfig: {
        select: response => selectTrendingArtistProfessions(response, rowLimit),
      },
    }),
  );

  const adConfigs = useMemo(
    () =>
      getAdvertisementConfigs({
        identifiers: [AD_TAGS.ARTIST, AD_TAGS.CASTING],
        identifierType: AD_IDENTIFIER_TYPE.TAG,
        placement: AD_PLACEMENTS.INTERSTITIAL,
      }),
    [],
  );

  const getFooterAd = useCallback(
    ({ index, count }) => {
      if (index === 0) {
        return <ConversionFlowBanners />;
      }

      if (index % 3 === 0) {
        return (
          <AdvertisementRenderer
            config={adConfigs[(count + index) % adConfigs.length]}
            section={trackingData.section}
            isSmall={artistsPerRow < 8}
          />
        );
      }

      return null;
    },
    [adConfigs, artistsPerRow],
  );

  return (
    <div>
      {data?.slice(0, visibleRows)?.map((profession, index) => (
        <TrendingArtistProfessionSwimlane
          key={profession?.slug}
          index={index}
          profession={profession}
          limit={artistsPerRow}
          getFooterAd={getFooterAd}
        />
      ))}
      {!rowLimit && data?.length > visibleRows && <div ref={ref} />}
      {rowLimit && (
        <TertiaryButton
          shape="rectangle"
          rightIcon={<SpriteIcon icon="chevron_right" />}
          variant="text"
          isLink
          {...navigate.getLinkProps({
            entityType: ENTITY_TYPE.ARTIST,
            onlyLinkProps: true,
          })}
          trackingData={seeAllTrackingData}
          stretch
        >
          <Typography color="link" weight="medium">
            {t(`${TP}.FN_VIEW_ALL_ARTISTS`)}
          </Typography>
        </TertiaryButton>
      )}
    </div>
  );
};

export default TrendingArtistProfessions;
