import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import classnames from 'classnames';

import BaseWrapper from 'components/Globals/Layout/IndexPageLayout/BaseWrapper';
import Explore from 'components/Globals/Layout/StubPage/Explore';
import ButtonTabs from 'components/uiLibrary/ButtonTabs';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import useCampaignAttributionDetails from 'utils/hooks/useCampaignAttributionDetails';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useTranslation } from 'src/i18n';
import { TP } from 'constants/index';

import PerformancesToday from './PerformancesToday';
import ProductionPerformanceSearch from './ProductionPerformanceSearch';
import FeaturedArtistsSection from './FeaturedArtistsSection';

import classes from './Home.module.scss';

const exploreMoreTrackingData = {
  section: SECTIONS.EXPLORE_MORE,
  component: COMPONENTS.NAVIGATE_CTA,
};

const Home = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isMobile } = useDeviceTypeLayouts();
  const { utmContent } = useCampaignAttributionDetails();
  const isScrolled = useScrollTrigger({ disableHysteresis: true, threshold: 420 });

  const [activeTab, setActiveTab] = useState(utmContent ? t(`${TP}.m_ARTISTS`) : t(`${TP}.FN_PERFORMANCES`));

  const tabs = useMemo(() => {
    const tabNames = [t(`${TP}.FN_PERFORMANCES`), t(`${TP}.m_ARTISTS`)];
    return utmContent ? tabNames.reverse() : tabNames;
  }, [utmContent, t]);

  const performanceRef = useRef(null);
  const featuredArtistRef = useRef(null);

  const scrollToElement = useCallback(elementRef => {
    const elementPosition = elementRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - 290;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }, []);

  const handleIntersection = useCallback(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newActiveTab =
            entry.target === featuredArtistRef.current ? t(`${TP}.m_ARTISTS`) : t(`${TP}.FN_PERFORMANCES`);
          setActiveTab(newActiveTab);
        }
      });
    },
    [t],
  );

  const handleTabChange = useCallback(
    newTab => {
      setActiveTab(newTab);
      const targetRef = newTab === t(`${TP}.m_ARTISTS`) ? featuredArtistRef : performanceRef;
      setActiveTab(newTab);
      scrollToElement(targetRef);

      setTimeout(() => {
        const entry = {
          target: targetRef.current,
          isIntersecting: true,
          intersectionRatio: 1,
          boundingClientRect: targetRef.current.getBoundingClientRect(),
        };
        handleIntersection([entry]);
      }, 100);
    },
    [t, scrollToElement],
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-45% 0px -45% 0px',
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    const currentPerformanceRef = performanceRef.current;
    const currentFeaturedArtistRef = featuredArtistRef.current;

    if (currentPerformanceRef) observer.observe(currentPerformanceRef);
    if (currentFeaturedArtistRef) observer.observe(currentFeaturedArtistRef);

    return () => {
      if (currentPerformanceRef) observer.unobserve(currentPerformanceRef);
      if (currentFeaturedArtistRef) observer.unobserve(currentFeaturedArtistRef);
    };
  }, [handleIntersection]);

  return (
    <BaseWrapper>
      <div className={classes.root}>
        {isMobile && (
          <div
            className={classnames(classes.root__tabsContainer, {
              [classes.root__tabsContainerSticky]: isScrolled,
            })}
          >
            <ButtonTabs tabs={tabs} onClick={handleTabChange} value={activeTab} />
          </div>
        )}
        <div
          className={classnames(classes.root__mainContainer, {
            [classes.root__mainContainerMobile]: !!utmContent && !!isMobile,
          })}
        >
          <div className={classes.root__mainContainer_left}>
            <ProductionPerformanceSearch />
            <div className={classes.root__performancesToday} ref={performanceRef}>
              <PerformancesToday />
            </div>
          </div>
          <div className={classes.root__mainContainer_right} ref={featuredArtistRef}>
            <FeaturedArtistsSection />
          </div>
        </div>
        {isMobile && (
          <div className={classes.root__explorePages}>
            <Explore trackingData={exploreMoreTrackingData} />
          </div>
        )}
      </div>
    </BaseWrapper>
  );
};

export default Home;
